import Intro from "../components/intro";

function Home()
{
    return(
        <>
            <Intro />
            <a href="/contacts" >
                to contact page
            </a>
        </>
    );
}

export default Home;