import { Box } from '@chakra-ui/react';
import logo from '../logo.svg';

function Intro() {
  return (
    <Box className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          This is my first React App. I am so excited to learn React. I am going to be a React Developer soon.
        </p>
      </header>

    </Box>
  );
}

export default Intro;