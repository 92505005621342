import { useEffect } from "react";
import { useState } from "react";

function Contacts() {

    const [regions, setRegions] = useState([]);

    useEffect(() => {
        const fetchReg = async () => {
            const reg = await fetch("https://api.idrissa-sall.com/decoupage-administratif/regions/dakar")
            const data = await reg.json();
            setRegions(data)
        }
        fetchReg();
    }, []);

    return (
        <div>
            <h1>Contact</h1>

            <div>
                <h2>Regions</h2>

                <div key={regions.id} className="my-regions">
                    <h3>{regions.name}</h3>
                    <p>{regions.code}</p>
                    <p>{regions.area}</p>
                </div>

            </div>
            <a href="/">
                to home page
            </a>
        </div>
    );
}

export default Contacts;